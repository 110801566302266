<template>
    <div class="business-main">
        <div class="sub-menu describe-main animate__animated animate__fadeInUp">
            <ul >
                <li ref="menu" v-for="(menu, index) in menuList" :key="index" @mouseout="targetOut(index)" @mouseover="targetHover(index)" @click="tagerMenu(index)">
                    <img :src="(selectMenu == index || moveIndex == index) ? menu.selectIcon : menu.icon">
                    <p :style="(selectMenu == index || moveIndex == index) ? 'color:#C20825;' : ''">{{menu.name}}</p>
                </li>
                <li class="menuActive active" ></li>
            </ul>
        </div>
        <div class="business-content">
            <img src="../static/images/business/03_bg.jpg">
            <div class="bg">
                <div v-show="isAnimate" class="title animate__animated animate__fadeInUp">
                    <p class="title-main">{{contentList[selectMenu].titleMain}}</p>
                    <p class="title-sub">{{contentList[selectMenu].titleSub}}</p>
                    <p class="title-content">{{contentList[selectMenu].titleContent}}</p>
                </div>
                <div class="content animate__animated animate__fadeInUp" >
                    <div class="swiper-container mySwiper" >
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" :style="!showLeftRight ? 'justify-content: center;' : 'justify-content: space-between;' " v-for="(content, index1) in contentList" :key="index1">
                                <div class="left-img" v-show="showLeftRight">
                                    <img :src="content.leftImg">
                                </div>
                                <div class="centerCon" :style="!showLeftRight ? 'width:85%;' : ''">
                                    <img :src="content.centerCon.img">
                                    <div class="con" >
                                        <p class="con-title">{{content.centerCon.title}}</p>
                                        <p class="con-value"  v-html="content.centerCon.content"></p>
                                    </div>
                                </div>
                                <div class="right-img" v-show="showLeftRight">
                                    <img :src="content.rightImg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/css/swiper.min.css'

    import foot from "../components/foot";
    import $ from "jquery";

    export default {
        name: "BusinessArea",
        components: {foot},
        data(){
            return {
                selectMenu: 0,
                moveIndex: -1,
                showLeftRight: true,
                conHeight: '300px',
                menuList: [
                    {
                        name: '户用分布式',
                        icon: require('@/static/images/business/icon_unselect_1.png'),
                        selectIcon: require('@/static/images/business/icon_select_1.png')
                    },
                    {
                        name: '工商业分布式',
                        icon: require('@/static/images/business/icon_unselect_2.png'),
                        selectIcon: require('@/static/images/business/icon_select_2.png')
                    },
                    {
                        name: '地面集中式',
                        icon: require('@/static/images/business/icon_unselect_3.png'),
                        selectIcon: require('@/static/images/business/icon_select_3.png')
                    },
                    {
                        name: '充电桩',
                        icon: require('@/static/images/business/icon_unselect_4.png'),
                        selectIcon: require('@/static/images/business/icon_select_4.png')
                    },
                    {
                        name: '储能',
                        icon: require('@/static/images/business/icon_unselect_5.png'),
                        selectIcon: require('@/static/images/business/icon_select_5.png')
                    }
                ],
                contentList: [
                    {
                        titleMain: "户用分布式光伏电站的应用",
                        titleSub: "共收益 富生活",
                        titleContent: "公司与农户共建电站，共享收益",
                        leftImg: require('@/static/images/business/con/03_jpg1_1.jpg'),
                        centerCon: {
                            title: "帝森共富宝",
                            content: "帝森共富宝是由农户提供合规屋顶，由帝森克罗德新能源公司出资，共同建设居民户用分布式光伏电站。</br>公司提供出资、设计、建设、运营、维护的一站式电站建设运营服务。电站收益农户和公司分享。 </br></br>实现了农户“不花钱、不贷款、享收益”的合作共建模式。",
                            img: require('@/static/images/business/con/03_jpg1_2.jpg')
                        },
                        rightImg: require('@/static/images/business/con/03_jpg1_3.jpg'),
                    },
                    {
                        titleMain: "工商业分布式光伏电站的应用",
                        titleSub: "租赁企业屋顶或合同能源管理",
                        titleContent: "公司投建电站，支付业主租金或电费打折，并承担业主屋顶维修维护",
                        leftImg: require('@/static/images/business/con/03_jpg2_1.jpg'),
                        centerCon: {
                            title: "企业合作",
                            content: "企业零投入，提供闲置工商业厂房屋顶，由我公司投资、建设及运营屋顶分布式光伏电站</br></br></br>企业可选长期电价折扣或收取屋顶租金；我公司也可根据企业用电特性定制光伏＋智慧能源管理方案，为企业进一步降低能耗，实现可视、可控、可优化的综合能源管理服务。",
                            img: require('@/static/images/business/con/03_jpg2_2.jpg')
                        },
                        rightImg: require('@/static/images/business/con/03_jpg2_3.jpg'),
                    },
                    {
                        titleMain: "地面集中式光伏电站的应用",
                        titleSub: "国有控股，实力雄厚、合作灵活、技术支撑",
                        titleContent: "适用范围广。未利用地、一般农田、滩涂用地、矿区用地、盐碱地、荒山、水面、湖面等均可建设",
                        leftImg: require('@/static/images/business/con/03_jpg3_1.jpg'),
                        centerCon: {
                            title: "合作共赢",
                            content: "提供可用于建设光伏电站的土地。明确光伏电站消纳和接入。可自主或合作开发项目，获取项目建设指标。",
                            img: require('@/static/images/business/con/03_jpg3_2.jpg')
                        },
                        rightImg: require('@/static/images/business/con/03_jpg3_3.jpg'),
                    },
                    {
                        titleMain: "充电桩项目的应用",
                        titleSub: "电网调节、市场巨大",
                        titleContent: "政策引导，使用便捷，需求量目益增加，是促进“碳中和”重要一环",
                        leftImg: require('@/static/images/business/con/03_jpg4_1.jpg'),
                        centerCon: {
                            title: "充电桩",
                            content: "随着新能源行业良性发展、国家和地方政府的积极引导、社会基础实施的需求、各方资本的布局投资，国内充电基础设施建设飞速发展，充电桩行业持续高速增长，特别是在2020年充电桩被纳入“新基建”。",
                            img: require('@/static/images/business/con/03_jpg4_2.jpg')
                        },
                        rightImg: require('@/static/images/business/con/03_jpg4_3.jpg'),
                    },
                    {
                        titleMain: "储能的应用",
                        titleSub: "移峰填谷，需量优化，享受多重收益，用电有保障",
                        titleContent: "提高储能能力将有效应对电力需求特性与负荷特性的差异，储能大发展即将到来。",
                        leftImg: require('@/static/images/business/con/03_jpg5_1.jpg'),
                        centerCon: {
                            title: "储能",
                            content: "主动参与电网调峰调频，获得调峰补贴；主动参与电力调度，获得辅助服务补贴；</br>支持共享储能，参与电力市场化交易，多种功能带来多重收益",
                            img: require('@/static/images/business/con/03_jpg5_2.jpg')
                        },
                        rightImg: require('@/static/images/business/con/03_jpg5_3.jpg'),
                    }
                ],
                isAnimate: true
            }
        },
        watch: {
            $route(){
                if (this.$route.query.i) {
                    this.selectMenu = this.$route.query.i
                    this.isAnimate = false
                    this.targetOut()
                    setTimeout(()=>{
                        this.isAnimate = true
                    },1)
                }
            }
        },
        mounted() {
            let that = this
            this.$bus.$emit("headerFixed", false)
            this.swiper2 = new Swiper('.mySwiper',{
                pagination: false,
                loop:true,
                speed:500,
                autoplay:{
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observer: true,
                observeParents: true,
                on: {
                    slideChange: function() {
                        that.selectMenu = this.activeIndex - 1
                        if (that.selectMenu == 5) {
                            that.selectMenu = 0
                        }
                        that.isAnimate = false
                        that.targetOut()
                        setTimeout(()=>{
                            that.isAnimate = true
                        },1)
                    }
                }
            })

            this.selectMenu = this.$route.query.i ? this.$route.query.i : 0

            if (this.selectMenu != 0) {
                this.isAnimate = false
                this.targetOut()
                setTimeout(()=>{
                    this.isAnimate = true
                },1)
            }

            let screenWidth = document.body.clientWidth
            if (screenWidth <= 1300) {
                that.showLeftRight = false
            } else {
                that.showLeftRight = true
            }

            window.onresize = () => {
                let screenWidth = document.body.clientWidth
                console.log(screenWidth)
                if (screenWidth <= 1300) {
                    that.showLeftRight = false
                } else {
                    that.showLeftRight = true
                }
            }

            document.querySelector(".centerCon").offsetHeight
        },
        methods: {
            targetOut() {
                this.moveIndex = -1
                let width = this.$refs.menu[this.selectMenu].offsetWidth - 144
                let left = this.$refs.menu[this.selectMenu].offsetLeft
                $(".menuActive").stop().animate({width: width + "px",left: left + "px"}, 300);
            },
            targetHover(index){
                this.moveIndex = index
                let width = this.$refs.menu[index].offsetWidth - 144
                let left = this.$refs.menu[index].offsetLeft
                $(".menuActive").stop().animate({width: width + "px",left: left + "px"}, 300);
            },
            tagerMenu(index){
                this.isAnimate = false

                this.swiper2.slideTo(index + 1, 1000, false)
                this.selectMenu = index
                setTimeout(()=>{
                    this.isAnimate = true
                },1)
            }
        }
    }
</script>

<style lang="less" scoped>
    .business-main {

        .sub-menu {
            height: 103px;
            background: #FAFBFD;
            box-shadow: 0px -1px 0px 0px rgba(243, 243, 243, 0.65);
            display: flex;
            flex-direction: row;
            justify-content: center;

            ul {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                li {
                    list-style: none;
                    padding: 0px 72px;
                    text-align: center;
                    cursor: pointer;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    &:hover {

                        p {
                            color: #C20825;
                        }
                    }

                    &.active {
                        position: absolute;
                        left: 0px;
                        bottom: -4px;
                        content: "";

                        height: 4px;
                        width:100px;
                        background: #FFFFFF;
                        z-index: 1;


                        p {
                            color: #C20825;
                        }
                    }
                }
            }
        }

        .business-content {
            position: relative;
            z-index: -1;
            height: 803px;

            img {
                height: 100%;
            }

            .bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;

                .title {
                    margin-top: 70px;
                    width: 100%;
                    text-align: center;

                    .title-main {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 60px;
                    }

                    .title-sub {
                        font-size: 30px;
                        font-family: PingFang SC;
                        font-weight: 100;
                        color: #FFFFFF;
                    }

                    .title-content {
                        font-size: 18px;
                        font-family: PingFang SC;
                        font-weight: 100;
                        color: #FFFFFF;
                        line-height: 50px;
                    }

                }

                .content {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 50px;


                    img {
                        height: 427.83px;
                    }

                    .left-img {
                        margin-right: 56px;
                    }

                    .right-img {
                        margin-left: 56px;
                    }

                    .centerCon {
                        display: flex;
                        flex-direction: row;
                        min-width: 845px;
                        width: 63%;
                        height: 99%;

                        img {
                            height: 427.83px;
                            width: auto;
                            font-size: 0px;
                        }

                        .con {
                            background-color: #FFFFFF;
                            border-radius: 0px 10px 10px 0px;
                            height: 100%;
                            position: relative;
                            left: -1px;
                            min-width: 240px;


                            &:before {
                                content: "";
                                width: calc(100% - 2px);
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                background-color: #DB2340;
                                height: 5px;
                                border-radius: 0px 100px 0px 0px;
                            }

                            .con-title {
                                font-size: 21px;
                                font-family: PingFang SC;
                                font-weight: 400;
                                color: #C20825;
                                padding: 50px 0px 26px 70px;
                            }

                            .con-value {
                                display: inline-block;
                                font-size: 14px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #333333;
                                padding: 0px 12%;

                                line-height: 22px;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }
</style>

<style lang="less">
    .mySwiper > .swiper-wrapper .swiper-slide {
        display: flex;
        justify-content: space-between;
    }
</style>
